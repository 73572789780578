.btn-out{
    border: none;
    background: none;
    font-size: 14px;
    padding: 8px 16px;
    width: 100%;
}
    .btn-out:hover{
        border: none;
        background: lightcyan;
        font-size: 14px;
    }