.bg {
    background-color: #002c4b !important;
    padding: 60px 30px 0px 30px;
    width: 100%;
}

.loading-spinner{
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    height: 100vh;

}

.red {
    background-color: red !important;
}
