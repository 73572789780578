nav{
	position:fixed !important;
	top:0;
	left:0;
	display:flex;
	flex-direction:column;
	justify-content: space-between;
	width:15%;
	min-height: 100vh;
	background-color: #203242;
	padding:2rem 1rem 1rem 1rem;
	transition: transform 1s;
	z-index: 1;
}

.navbar{
	transform: translateX(-100%);
}

.nav-btn{
	position: absolute;
	transform: translateX(38px);
	top: 73px;
	right: 0;
	width:40px;
	height: 60px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #753ffd;
	outline: none;
	border: none;
	font-size: 1rem;
	padding: 5px 10px;
	cursor: pointer;
	color: #fff;
}



.logo{
	display: block;
	width: 100px;
	margin: auto;
	background:transparent;
}

.logo img{
	display: block;
	width: 100%;
	height: 100%;
}

.nav-link{
	display: flex;
	align-items: center;
	color: #fff !important;
	text-decoration: none;
	padding: 10px 1rem !important;
	border-radius: 5px;
	margin-bottom: 5px;
}

.nav-link:hover{
	background-color:#AFB4FF;
	color: #4e4e4e;
}

.nav-link span{
	margin-left: 10px;
}

.nav-top{
	margin-top: 10rem;
}

.mobile-nav{
	background-color:#753ffd;
	width: 100%;
	height:40px;
	display: none;
}

.mobile-nav-btn{
	color: #fff;
	background: transparent;
	outline: none;
	border: none;
	color: #002c4b !important;
    position: fixed !important;
    top: 16px !important;
    left: 23px !important;
    z-index: 2 !important;
}

@media only screen and (max-width: 768px){
	nav{
		width: 40%;
		padding: 10px;
	}
}

@media only screen and (max-width: 450px){
	.mobile-nav{
		display: flex;
		align-items: center;
	}
	nav{
		width: 70%;
		top: 38px;
		min-height: calc(100vh - 38px);
	}

	.nav-btn{
		display: none;
	}
}
