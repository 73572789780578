.Logo-style {
    min-height: 43px;
    z-index: 100000;
  }

.head {
    transition: all 0.5s;
    z-index: 2;
    height: 60px;
    box-shadow: 0px 2px 20px rgb(1 41 112 / 10%);
    background-color: white;
}

.mLeftRight{
  padding-left: 70px;
  padding-right: 70px;
}

.btnCreateSupportTicket {
  background-color: #1f88eb;
  border: none;
  border-radius: 6px;
  color: #fff;
  font-size: 15px!important;
  padding: 6px 16px;
  width: auto!important;
  &:hover {
    border: 1px solid #1f88eb;
    background: none;
    color: #1f88eb;
  }
}