p {
    font-family: "Poppins", sans-serif;
    word-break: break-word;
    margin-bottom: 1rem;
    color: white;
}

.card-body a {
    position: absolute;
    bottom: 20px;
    left: 20px;
}
.card-body {
    margin-bottom: 30px;
    border: none;
    border-radius: 6px;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    margin-left: 16px;
    height: 12.586rem;
    min-width: 201px;
    position: relative;
    padding: 20px !important;
}

@media only screen and (min-width: 600px) {
    .card-body {
        max-width: 201px;
    }
  }

  .card-body-b {
    margin-bottom: 30px;
    border: none;
    border-radius: 6px;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    margin-left: 16px;
    height: 12.586rem;
    min-width: 201px;
    position: relative;
    padding: 20px !important;
    align-items: flex-start;
    transition: background 0.3s ease, transform 0.3s ease;
    &:hover {
        background: rgba(255, 0, 0, 0.7) !important;
    }
}

@media only screen and (min-width: 600px) {
    .card-body-b {
        max-width: 201px;
    }
  }

@media only screen and (max-width: 1024px) {
  .try {
    margin-right: 12px;
  }
}

.card-title {
    font-size: 21px;
    line-height: 27.2px;
    color: white;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    margin-bottom: 8px !important;
}

.g-button {
    border: 1px solid white;
    padding: 7px;
    padding: 8px 18px;
    border-radius: 6px;
    color: white;
    font-size: 1rem;
    text-decoration: none;
}

.g-button:hover {
    background-color: white;
    padding: 7px;
    border: none;
    padding: 8px 18px;
    border-radius: 6px;
    color: #002C4B;
    font-size: 1rem;
}

.p-0 {
    padding-left: 0 !important;
}

