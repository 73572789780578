*{
	padding: 0;
	margin: 0;
	box-sizing: border-box;

}

.App{
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100vh;
}

.page{
	flex-basis: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: margin-left 1s;
}

.page-with-navbar{
	margin-left: 15%;
}

@media only screen and (max-width: 768px){
	.page-with-navbar{
		width: 100%;
		margin-left: 0;
	}

}

.font-face-gm {
	font-family: "Poppins";
   }

@font-face {
	font-family: 'Poppins-SemiBold';
	src: local('Poppins-SemiBold'), url(../assets/fonts/montserrat-v24/Poppins-Medium.ttf) format('truetype');
  }
  
  @font-face {
	font-family: 'Poppins';
	src: local('Poppins'), url(../assets/fonts/montserrat-v24/Poppins-Regular.ttf) format('truetype');
  }

h1 {
    font-size: 50px !important;
	font-family: "Poppins";
}

h2 {
    font-size: 38px !important;
	font-family: "Montserrat";
}

h3 {
    font-size: 28px;
    font-weight: 600 !important;
}

h4 {
    font-size: 20px !important;
	font-weight: 600 !important;
	font-family: "Poppins";
}

h5 {
    font-size: 16px !important;
	font-family: "Poppins";
}

p {
    font-size: 14px !important;
	font-family: "Poppins";
}